import "core-js/modules/es6.object.assign";
import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import imagesLoaded from 'imagesloaded';
import { Figure, Grid, HomepageSection, SEO, Statement } from 'components';
import { useArrayRef, useNavIntro } from 'hooks';
import { renderText } from 'utils';
import styles from './styles/index.module.scss';

var HomePage = function HomePage(_ref) {
  var _ref$data$page = _ref.data.page,
      blocks = _ref$data$page.blocks,
      seo = _ref$data$page.seo,
      pathname = _ref.location.pathname;
  useNavIntro();
  /**
   * Reference value for if the component is mounted.
   */

  var isMounted = useRef(true);
  /**
   * Reference value for the array of figure refs.
   */

  var _useArrayRef = useArrayRef(),
      figuresRef = _useArrayRef[0],
      setFigureRef = _useArrayRef[1];
  /**
   * Reference value for the `render` function's animation frame,
   * so that it may be cancelled at a later point.
   */


  var renderAnimationFrame = useRef(null);
  /**
   * Whether or not the images in each figure have all been loaded.
   * Used to determine whether to start rendering the scroll
   * effect or not.
   */

  var _useState = useState(false),
      isLoaded = _useState[0],
      setIsLoaded = _useState[1];
  /**
   * Memoized value for the different sections in the homepage.
   * Assigns a `sectionIdx` value to each, which is the index of
   * the section within just its type (i.e.: `i` may equal 6, but
   * `sectionIdx` may equal 2 because it's the second figure block).
   */


  var sections = useMemo(function () {
    var figureIdx = 0;
    var statementIdx = 0;
    return blocks.map(function (section) {
      var sectionIdx;

      if (section.image) {
        sectionIdx = figureIdx;
        figureIdx += 1;
      } else {
        sectionIdx = statementIdx;
        statementIdx += 1;
      }

      return Object.assign({}, section, {
        sectionIdx: sectionIdx
      });
    });
  }, [blocks]);
  /**
   * Render function which updates and renders the figures on
   * the homepage.
   */

  var render = function render() {
    if (isMounted.current) {
      figuresRef.current.forEach(function (_ref2) {
        var isInsideViewport = _ref2.isInsideViewport,
            isVisible = _ref2.isVisible,
            render = _ref2.render,
            update = _ref2.update;

        if (isVisible.current) {
          if (isInsideViewport.current) {
            render();
          } else {
            isInsideViewport.current = true;
            update();
          }
        } else if (isInsideViewport.current) {
          isInsideViewport.current = false;
        }
      });
      renderAnimationFrame.current = requestAnimationFrame(render);
    }
  };

  useEffect(function () {
    if (isLoaded) {
      render();
      return function () {
        if (renderAnimationFrame.current) {
          cancelAnimationFrame(renderAnimationFrame.current);
          renderAnimationFrame.current = null;
        }
      };
    }
  }, [isLoaded]);
  useEffect(function () {
    if (figuresRef.current) {
      var images = figuresRef.current.reduce(function (acc, _ref3) {
        var imgEl = _ref3.imgEl;

        if (imgEl) {
          acc.push(imgEl);
        }

        return acc;
      }, []);

      if (images.length > 0) {
        imagesLoaded(images, {
          background: true
        }, function () {
          setIsLoaded(true);
        });
      }
    }

    return function () {
      isMounted.current = false;
    };
  }, []);
  return React.createElement(Grid, {
    className: styles.page,
    hasMaxWidth: true,
    tag: "section"
  }, React.createElement(SEO, Object.assign({}, seo, {
    pathname: pathname
  })), sections.map(function (_ref4, i) {
    var body = _ref4.body,
        image = _ref4.image,
        sectionIdx = _ref4.sectionIdx,
        title = _ref4.title;
    var isMedia = !!image;
    var content = isMedia ? React.createElement(Figure, {
      ref: setFigureRef(sectionIdx),
      alt: image.title,
      captionBody: body,
      captionHeading: title,
      image: image.file,
      isLoaded: isLoaded
    }) : React.createElement(Statement, {
      title: title
    }, renderText(body));
    return React.createElement(HomepageSection, {
      key: i,
      isMedia: isMedia
    }, content);
  }));
};

HomePage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      blocks: PropTypes.arrayOf(PropTypes.object).isRequired,
      seo: PropTypes.object.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};
export default HomePage;
export var query = "2880889146";